import { HomeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'src/assets/images/logo.png';
import { useAuth } from 'src/contexts';

export const TopNav = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return (
    <>
      <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
        <NavLink to="/">
          <img src={logo} className="logo" alt="" />
        </NavLink>
        <Menu.Item key="/" icon={<HomeOutlined />}>
          <NavLink to="/">Home</NavLink>
        </Menu.Item>
        {!isLoggedIn && (
          <Menu.Item key="/apply" icon={<HomeOutlined />}>
            <NavLink to="/apply">Apply Now!</NavLink>
          </Menu.Item>
        )}
        {isLoggedIn ? (
          <Menu.Item key="/logout" style={{ float: 'right' }}>
            <NavLink to="/logout">Logout</NavLink>
          </Menu.Item>
        ) : (
          <Menu.Item key="/login" style={{ float: 'right' }}>
            <NavLink to="/login">Login</NavLink>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
};
