import { AuthClientTokens } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Col, Layout, Spin } from 'antd';
import Keycloak from 'keycloak-js';
import React from 'react';
import { TopNav } from 'src/components';
import { AuthProvider, HopperApiProvider, useAuth } from 'src/contexts';
import { Router } from 'src/Router';
import './App.css';

const { Header, Content, Footer } = Layout;

const App = () => {
  const { setToken } = useAuth();

  const keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
  });

  const onTokens = (tokens: AuthClientTokens) => {
    if (tokens.token) {
      setToken(tokens.token);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'check-sso' }}
      onTokens={onTokens}
      LoadingComponent={<Spin />}
    >
      <AuthProvider>
        <HopperApiProvider>
          <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
              <Col md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 14, offset: 5 }}>
                <TopNav />
              </Col>
            </Header>
            <Content className="site-layout">
              <Col md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 14, offset: 5 }}>
                <div className="site-layout-background">
                  <Router />
                </div>
              </Col>
            </Content>
            <Footer style={{ textAlign: 'center' }}>&copy;2021 Zym Labs</Footer>
          </Layout>
        </HopperApiProvider>
      </AuthProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
