import { Button, Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { PAY_FREQUENCIES } from 'src/constants';
import { useApplicationWizard } from 'src/contexts';

interface FormData {
  payFrequency: string;
  netMonthlyIncome: number;
  lastPayDate: Date;
  nextPaydate: Date;
}

export const IncomeVerificationPage = () => {
  const [form] = Form.useForm();
  const { nextStep } = useApplicationWizard();

  const handleSubmit = (formData: FormData) => {
    console.log(formData);
    nextStep();
  };

  const initialValues = {
    payFrequency: null,
    netMonthlyIncome: null,
    lastPayDate: null,
    nextPaydate: null,
  };

  return (
    <>
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              name="payFrequency"
              label="How often are you paid?"
              rules={[{ required: true, message: 'Please select a pay frequency' }]}
            >
              <Radio.Group>
                {PAY_FREQUENCIES.map(({ value, label }) => (
                  <Radio.Button value={value}>{label}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="lastPayDate" label="Last Pay Date">
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="nextPayDate" label="Next Pay Date">
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item name="netMonthlyIncome" label="Net Monthly Income">
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form>
    </>
  );
};
