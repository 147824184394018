import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ApplicationWizardProvider, useAuth } from 'src/contexts';
import { ApplyPage, HomePage, LoginPage, LogoutPage, VerifyPage } from 'src/pages';
import { useQueryParam } from 'use-query-params';

export const Router = () => {
  const history = useHistory();
  const [token] = useQueryParam<string>('token');
  const { setToken } = useAuth();

  useEffect(() => {
    if (token) {
      setToken(atob(token));
      history.replace(history.location.pathname);
    }
  }, [history, setToken, token]);

  return (
    <Switch>
      <ApplicationWizardProvider>
        <Route path="/" exact component={HomePage} />
        <Route path="/apply" exact component={ApplyPage} />
        <Route path="/apply/:applicationId" exact component={ApplyPage} />
        <Route path="/verify" exact component={VerifyPage} />
        <Route path="/verify/:applicationId" exact component={VerifyPage} />
      </ApplicationWizardProvider>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/logout" exact component={LogoutPage} />
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  );
};
