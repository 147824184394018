import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth, useHopper } from 'src/contexts';

export const ApplyPage = () => {
  const { token } = useAuth();
  const { applicationId } = useParams<{ applicationId?: string }>();
  const { applicationsApi } = useHopper();
  const history = useHistory();

  useEffect(() => {
    if (applicationId && token) {
      applicationsApi.applicationsGetDetail(parseInt(applicationId)).then(({ data: application }) => {
        console.log(application);
        // TODO: determine which step application is on
        history.push('/verify', { application });
      });
    }
  }, [history, applicationsApi, applicationId, token]);

  return null;
};
