import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DisclosuresPage, ElectronicSignaturePage, IncomeVerificationPage, PaymentOptionPage } from 'src/pages';
import { useAuth } from './AuthContext';
import { useHopper } from './HopperApiContext';

interface ApplicationWizardInterface {
  application: any | null;
  initApplication: (application: any) => void;
  step: number;
  steps: any[];
  prevStep: () => void;
  nextStep: () => void;
}

const ApplicationWizardContext = createContext<ApplicationWizardInterface>({
  application: null,
  initApplication: () => null,
  step: 0,
  steps: [],
  prevStep: () => null,
  nextStep: () => null,
});

/*
If accepted:
  - Landing page
  - Disclosures
  - Pay date/Income amount verification
  - Funding/Payment method
  - E-sign

If flagged for review:
  - Landing page
  - Disclosures
  - Pay date/Income amount verification
  - Request additional information (drivers license or bank account verification)
  - Funding/Payment method
  - E-sign
 */

const verificationSteps = [
  // Landing Page (not a step)
  {
    title: 'Disclosures',
    content: () => <DisclosuresPage />,
  },
  {
    title: 'Income Verification',
    content: () => <IncomeVerificationPage />,
  },
  {
    title: 'Payment Info',
    content: () => <PaymentOptionPage />,
  },
  {
    title: 'E-Sign',
    content: () => <ElectronicSignaturePage />,
  },
];

export const ApplicationWizardProvider = ({ children }: any) => {
  const history = useHistory();
  const { isLoggedIn } = useAuth();
  const { applicationsApi } = useHopper();
  const [step, setStep] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([]);
  const [application, setApplication] = useState<any | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      applicationsApi.applicationsGetList(1, 10, '-id').then(({ data: { items: applications } }) => {
        const acceptedApplication = applications.find((a) => a.status === 'Accepted');
        if (acceptedApplication) {
          setApplication(acceptedApplication);
        }
      });
    }
  }, [isLoggedIn, applicationsApi]);

  useEffect(() => {
    if (application && application.status === 'Accepted') {
      setSteps(verificationSteps);
    }
  }, [application]);

  const initApplication = (application: any) => {
    console.log('initApplication');
    setApplication(application);
    // TODO: determine which step application is on
    history.push('/verify', { application });
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <ApplicationWizardContext.Provider value={{ application, initApplication, step, steps, prevStep, nextStep }}>
      {children}
    </ApplicationWizardContext.Provider>
  );
};

export const useApplicationWizard = () => useContext(ApplicationWizardContext);
