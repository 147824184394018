import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/contexts';

export const LoginPage = () => {
  const { isLoggedIn, login } = useAuth();

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  login();

  return null;
};
