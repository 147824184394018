import { Button, Col, Form, Radio, Row } from 'antd';
import { FUNDING_METHODS, PAYMENT_METHODS } from 'src/constants';
import { useApplicationWizard } from 'src/contexts';

export const PaymentOptionPage = () => {
  const [form] = Form.useForm();
  const { nextStep } = useApplicationWizard();

  const handleSubmit = (formData: FormData) => {
    console.log(formData);
    nextStep();
  };

  const application = {
    paymentOption: {
      paymentMethod: 'ACH',
    },
    fundingOption: {
      fundingMethod: 'ACH',
    },
  };

  const initialValues = {
    paymentOption: application.paymentOption,
    fundingOption: application.fundingOption,
  };

  return (
    <>
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item name={['paymentOption', 'paymentMethod']} label="Payment Method">
              <Radio.Group>
                {PAYMENT_METHODS.map(({ value, label }) => (
                  <Radio.Button value={value}>{label}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item name={['fundingOption', 'fundingMethod']} label="Funding Method">
              <Radio.Group>
                {FUNDING_METHODS.map(({ value, label }) => (
                  <Radio.Button value={value}>{label}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form>
    </>
  );
};
