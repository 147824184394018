import { Spin, Steps } from 'antd';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { TABLET } from 'src/constants';
import { useApplicationWizard, useAuth, useHopper } from 'src/contexts';

export const VerifyPage = () => {
  const isMobile = useMediaQuery({ maxWidth: TABLET });
  const { isLoggedIn } = useAuth();
  const { applicationId } = useParams<{ applicationId?: string }>();
  const { applicationsApi } = useHopper();
  const { application, initApplication, step, steps } = useApplicationWizard();

  useEffect(() => {
    if (!application && applicationId && isLoggedIn) {
      applicationsApi.applicationsGetDetail(parseInt(applicationId)).then(({ data: application }) => {
        initApplication(application);
      });
    }
  }, [application, applicationsApi, applicationId, isLoggedIn, initApplication]);

  if (steps.length === 0) {
    return <Spin />;
  }

  return (
    <>
      <Steps size="small" direction={isMobile ? 'vertical' : 'horizontal'} current={step}>
        {steps.map((item) => (
          <Steps.Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div style={{ paddingTop: '24px' }} />
      {steps[step].content()}
    </>
  );
};
