import {
  ApplicationApplicantsApi,
  ApplicationBankAccountsApi,
  ApplicationFlagsApi,
  ApplicationMetadataApi,
  ApplicationsApi,
  ApplicationUnderwritingReasonsApi,
  ApplicationUnderwritingsApi,
  ApplicationUnderwritingTransactionsApi,
  Configuration,
  FlagsApi,
  LeadProviderCampaignsApi,
  LeadProvidersApi,
} from '@zymlabs/hopper-axios-sdk';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

interface HopperApiInterface {
  applicationsApi: ApplicationsApi;
  applicationApplicantsApi: ApplicationApplicantsApi;
  applicationBankAccountsApi: ApplicationBankAccountsApi;
  applicationFlagsApi: ApplicationFlagsApi;
  applicationMetadataApi: ApplicationMetadataApi;
  applicationUnderwritingsApi: ApplicationUnderwritingsApi;
  applicationUnderwritingReasonsApi: ApplicationUnderwritingReasonsApi;
  applicationUnderwritingTransactionsApi: ApplicationUnderwritingTransactionsApi;
  flagsApi: FlagsApi;
  leadProvidersApi: LeadProvidersApi;
  leadProviderCampaignsApi: LeadProviderCampaignsApi;
}

const HopperApiContext = createContext<HopperApiInterface>({
  applicationsApi: new ApplicationsApi(),
  applicationApplicantsApi: new ApplicationApplicantsApi(),
  applicationBankAccountsApi: new ApplicationBankAccountsApi(),
  applicationFlagsApi: new ApplicationFlagsApi(),
  applicationMetadataApi: new ApplicationMetadataApi(),
  applicationUnderwritingsApi: new ApplicationUnderwritingsApi(),
  applicationUnderwritingReasonsApi: new ApplicationUnderwritingReasonsApi(),
  applicationUnderwritingTransactionsApi: new ApplicationUnderwritingTransactionsApi(),
  flagsApi: new FlagsApi(),
  leadProvidersApi: new LeadProvidersApi(),
  leadProviderCampaignsApi: new LeadProviderCampaignsApi(),
});

export const HopperApiProvider = ({ children }: any) => {
  const { token } = useAuth();

  const [applicationsApi, setApplicationsApi] = useState(new ApplicationsApi());
  const [applicationApplicantsApi, setApplicationApplicantsApi] = useState(new ApplicationApplicantsApi());
  const [applicationBankAccountsApi, setApplicationBankAccountsApi] = useState(new ApplicationBankAccountsApi());
  const [applicationFlagsApi, setApplicationFlagsApi] = useState(new ApplicationFlagsApi());
  const [applicationMetadataApi, setApplicationMetadataApi] = useState(new ApplicationMetadataApi());
  const [applicationUnderwritingsApi, setApplicationUnderwritingsApi] = useState(new ApplicationUnderwritingsApi());
  const [applicationUnderwritingReasonsApi, setApplicationUnderwritingReasonsApi] = useState(
    new ApplicationUnderwritingReasonsApi(),
  );
  const [applicationUnderwritingTransactionsApi, setApplicationUnderwritingTransactionsApi] = useState(
    new ApplicationUnderwritingTransactionsApi(),
  );
  const [flagsApi, setFlagsApi] = useState(new FlagsApi());
  const [leadProvidersApi, setLeadProvidersApi] = useState(new LeadProvidersApi());
  const [leadProviderCampaignsApi, setLeadProviderCampaignsApi] = useState(new LeadProviderCampaignsApi());

  useEffect(() => {
    if (token) {
      const configuration = new Configuration({
        basePath: process.env.REACT_APP_HOPPER_API_URL,
        accessToken: token,
      });
      setApplicationsApi(new ApplicationsApi(configuration));
      setApplicationApplicantsApi(new ApplicationApplicantsApi(configuration));
      setApplicationBankAccountsApi(new ApplicationBankAccountsApi(configuration));
      setApplicationFlagsApi(new ApplicationFlagsApi(configuration));
      setApplicationMetadataApi(new ApplicationMetadataApi(configuration));
      setApplicationUnderwritingsApi(new ApplicationUnderwritingsApi(configuration));
      setApplicationUnderwritingReasonsApi(new ApplicationUnderwritingReasonsApi(configuration));
      setApplicationUnderwritingTransactionsApi(new ApplicationUnderwritingTransactionsApi(configuration));
      setFlagsApi(new FlagsApi(configuration));
      setLeadProvidersApi(new LeadProvidersApi(configuration));
      setLeadProviderCampaignsApi(new LeadProviderCampaignsApi(configuration));
    }
  }, [token]);

  return (
    <HopperApiContext.Provider
      value={{
        applicationsApi,
        applicationApplicantsApi,
        applicationBankAccountsApi,
        applicationFlagsApi,
        applicationMetadataApi,
        applicationUnderwritingsApi,
        applicationUnderwritingReasonsApi,
        applicationUnderwritingTransactionsApi,
        flagsApi,
        leadProvidersApi,
        leadProviderCampaignsApi,
      }}
    >
      {children}
    </HopperApiContext.Provider>
  );
};

export const useHopper = () => useContext(HopperApiContext);
