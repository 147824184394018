import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/contexts';

export const LogoutPage = () => {
  const { logout } = useAuth();

  logout();

  return <Redirect to="/" />;
};
