import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useApplicationWizard } from 'src/contexts';

export const HomePage = () => {
  const { application } = useApplicationWizard();

  return (
    <>
      {application ? (
        <Link to={`/verify/${application.id}`}>
          <Button type="primary">Continue Application</Button>
        </Link>
      ) : (
        <Link to="/apply">
          <Button type="primary">Apply Now</Button>
        </Link>
      )}
    </>
  );
};
