import { Button, Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { useApplicationWizard } from 'src/contexts';

// TODO: get these from application checklist items
const disclosures = [
  {
    id: 1,
    title: 'Test Disclosure #1',
    content:
      'Bacon ipsum dolor amet sirloin pork jerky rump prosciutto landjaeger tenderloin t-bone. Filet mignon turkey bacon flank salami tail venison burgdoggen boudin frankfurter spare ribs tenderloin picanha leberkas. Cow ribeye hamburger rump, prosciutto swine flank jowl picanha sausage pork loin boudin ground round. Shankle ham hock chuck cupim tenderloin chicken boudin, pancetta rump brisket hamburger pork chop drumstick. Brisket venison kevin swine. Buffalo tri-tip pork chop, bacon shank hamburger biltong jerky picanha sausage chuck doner turducken turkey. Leberkas strip steak tongue shankle, pancetta cupim corned beef landjaeger chislic fatback chuck sirloin shoulder tri-tip.',
    required: true,
  },
  {
    id: 2,
    title: 'Test Disclosure #2',
    content:
      'Landjaeger corned beef turducken boudin tongue jowl biltong picanha meatloaf capicola sausage. Bacon andouille chicken rump pig. Drumstick chicken chislic fatback meatloaf bresaola pastrami alcatra beef salami sirloin shoulder rump pork belly short ribs. Rump hamburger drumstick short ribs fatback, pork loin ribeye picanha kevin leberkas meatball. Turducken pork belly cow boudin frankfurter ground round.',
    required: false,
  },
  {
    id: 3,
    title: 'Test Disclosure #3',
    content:
      'Ham cow t-bone meatball, jowl kevin short ribs beef ribs short loin landjaeger. Doner frankfurter chislic jowl. Alcatra frankfurter burgdoggen, andouille turkey short ribs turducken shank ball tip swine kielbasa sausage tri-tip. Jowl capicola salami ham hock rump boudin ham ball tip frankfurter pork belly. Pastrami turducken pork chop alcatra landjaeger pork belly. Andouille ribeye tenderloin filet mignon corned beef shankle, prosciutto drumstick ham hock jerky venison alcatra chuck.',
    required: false,
  },
];

interface FormData {
  name: string;
  disclosures: boolean[];
}

export const DisclosuresPage = () => {
  const [form] = Form.useForm();
  const { nextStep } = useApplicationWizard();

  const handleSubmit = async (formData: FormData) => {
    console.log(formData);
    nextStep();
  };

  const initialValues = {
    name: '',
    disclosures: disclosures.map(() => false),
  };

  return (
    <>
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
        {disclosures.map((disclosure, i) => (
          <Card title={disclosure.title}>
            <Row gutter={8}>
              <Col xs={24}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={disclosure.content} />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={24}>
                <Form.Item
                  name={['disclosures', i]}
                  valuePropName="checked"
                  rules={[
                    {
                      required: disclosure.required,
                      transform: (value) => value || undefined,
                      type: 'boolean',
                      message: 'Please agree to this disclosure',
                    },
                  ]}
                >
                  <Checkbox>I agree</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        ))}
        <Row gutter={8}>
          <Col xs={24} md={12} lg={6}>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[{ required: true, message: 'Please enter your full name' }]}
            >
              <Input style={{ textTransform: 'uppercase' }} />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </Form>
    </>
  );
};
